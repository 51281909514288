<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">年終績效評估</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="!grid.isEditing && canCreate"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome
            icon="plus"
            type="fas"
            class="w-4 h-4 mr-1"
          />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="initAddData"
          @edit="onGridEdit"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #flow="{ row }">
            <vxe-button
              type="button"
              content="查看"
              @click="flowModal.show(row)"
            ></vxe-button>
          </template>
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="formSubmit(row, submit)"
            >
              <template #column-file="{ data }">
                <DocumentFileUpload
                  v-if="data.Id"
                  ref="fileGrid"
                  :title="''"
                  v-bind="fileModal.uploadOptions"
                  :canUpload="!readType"
                  :canDelete="!readType"
                />
              </template>
              <template #column-submitter="{ data }">
                <select-box
                  v-if="data"
                  v-bind="userSelectorOptions"
                  :readonly="readType"
                  :disabled="readType"
                  v-model="data.SubmitterId"
                  @change="changeUser(data)"
                />
              </template>
              <template #column-department="{ data }">
                <select-box
                  v-if="data.XmlData"
                  v-bind="departmentSelectorOptions"
                  :readonly="true"
                  :disabled="true"
                  v-model="data.XmlData.DepartmentId"
                />
              </template>
              <!-- 年終績效評估 34 -->
              <!-- <template #column-PerformanceEvaluationItemsA="{ data }">
                <PerformanceEvaluationItemsATable
                  v-if="data.XmlData"
                  ref="PerformanceEvaluationItemsATable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.ItemsA"
                  :readType="readType"
                />
              </template> -->
              <!-- <template #column-PerformanceEvaluationItemsB="{ data }">
                <PerformanceEvaluationItemsBTable
                  v-if="data.XmlData"
                  ref="PerformanceEvaluationItemsBTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.ItemsB"
                  :readType="readType"
                />
              </template> -->
              <template #column-hr>
                <div class="border-t border-gray-300"></div>
              </template>
              <template #column-PerformanceEvaluationPartA>
                <div>
                  <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                    <thead>
                      <tr>
                        <th
                          class="border border-gray-400 bg-gray-200 py-2 px-4 text-center"
                          colspan="5"
                        >整體績效評核標準</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          <span class="font-black">C</span>oach Needed 需輔導
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          <span class="font-black">I</span>mprovement Needed 需改進
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          <span class="font-black">G</span>ood 良好
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          <span class="font-black">D</span>istinguished 優秀
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          <span class="font-black">E</span>xcellent 傑出
                        </td>
                      </tr>
                      <!-- <tr>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Does not meet requirements of the job. A poor performer who consistently fails to get the job
                          done.<br>
                          無法達成工作目標，在完成工作的過程之中，持續不斷的出錯或無法完成工作任務。
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Meets some but not all requirements of the job. Not entirely performing up to the necessary
                          level. This may be a new job assignment and growth is needed to meet full job requirements.<br>
                          僅達成部分工作目標，而且工作成果沒有達到應有品質水準。
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Meets requirements of the job. A solid consistent performer who gets the job done in a
                          participative manner.<br>
                          完成設定的工作目標，可以確實的完成主管交付的工作任務。
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Exceeds most requirements of the job. Dose more than normally expected. In most parts of the job
                          the work is truly outstanding. but in all cases it meets the requirements of the job employing
                          participate techniques.<br>
                          績效超越原設定的工作目標，且常有超出預期的良好表現。
                        </td>
                        <td
                          class="border border-gray-400 py-2 px-4"
                          width="20%"
                        >
                          Exceeds all requirements of the job. An outstanding performer in terms of every requirement of
                          the job employing participate techniques.<br>
                          表現卓越的績效，除了主管交辦的任務可以表現傑出的完成外，經常以創新主動的表現，超越主管的期望。
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </template>
              <template #directions01>
                <div class="w-full ml-3">
                  Reminding:<br>
                  1.Please make sure you had completed reading the guideline before your performance review.<br>
                  2.Employee should fill all the parts which make as <span class="font-black">！</span><br>
                  請注意:<br>
                  1.請在開始填寫本績效評估表前，務必仔細閱讀詳細說明<br>
                  2.表格中有「<span class="font-black">！</span>」符號者，表示需由員工填寫完成
                </div>
              </template>
              <template #partA="{ data }">
                <div class="w-full ml-3">
                  <span class="font-black">PART A - SELF PERFORMANCE APPRAISAL 個人績效評估</span>
                </div>
                <table class="w-full table-auto w-auto border-collapse border border-gray-400 my-4">
                  <thead>
                    <tr>
                      <th
                        class="border border-gray-400 bg-gray-200 py-2 px-4 text-center font-black"
                        colspan="5"
                      >Objectives Achievement 工作目標達成</th>
                    </tr>
                  </thead>
                </table>
                <div class="w-full ml-3 my-4">
                  A.<span class="font-black">！</span>Beneath each performance objective summarize and results during
                  the
                  review period.(Major 3-5 Items)
                  員工條列三至五項具體完成事項說明，並整理評估期間之達成目標
                </div>
                <PerformanceEvaluationLastYearItemsATable
                  v-if="data.XmlData.LastYearItemsA"
                  ref="PerformanceEvaluationLastYearItemsATable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.LastYearItemsA"
                  :readType="readType"
                />
                <PerformanceEvaluationItemsATable
                  v-if="data.XmlData"
                  ref="PerformanceEvaluationItemsATable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.ItemsA"
                  :readType="readType"
                />
                <div class="w-full ml-3 my-4">
                  B.<span class="font-black">！</span>Give constructive suggestive suggestions for how performance
                  could be improved
                  就未完事項，提出具提建設性自我改進建議
                </div>
                <PerformanceEvaluationLastYearItemsBTable
                  v-if="data.XmlData.LastYearItemsB"
                  ref="PerformanceEvaluationLastYearItemsBTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.LastYearItemsB"
                  :readType="readType"
                />
                <PerformanceEvaluationItemsBTable
                  v-if="data.XmlData"
                  ref="PerformanceEvaluationItemsBTable"
                  :contentType="data.ContentType"
                  :aid="data.Id"
                  @refresh="refresh"
                  :data="data.XmlData.ItemsB"
                  :readType="readType"
                />
                <table class="w-full table-auto w-auto border-collapse border border-gray-400 my-4">
                  <thead>
                    <tr>
                      <th
                        class="border border-gray-400 bg-gray-200 py-2 px-4 flex justify-center font-black"
                        colspan="5"
                      >Core Competency Evaluation 核心職能評核
                        <span @click="fold('CoreCompetencyEvaluation')">
                          <!-- <ChevronRightIcon
                            class="top-menu__sub-icon"
                            v-show="!foldSetting.CoreCompetencyEvaluation"
                          />
                          <ChevronDownIcon
                            class="top-menu__sub-icon"
                            v-show="foldSetting.CoreCompetencyEvaluation"
                          /> -->
                        </span>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div v-show="foldSetting.CoreCompetencyEvaluation">
                  <div class="flex justify-end mb-2">
                    <div class="w-1/5 text-center "><span class="text-red-500 mr-1">*</span>員工自評-去年 Employee-LastYear</div>
                    <div class="w-1/5 text-center "><span class="text-red-500 mr-1">*</span>員工自評 Employee</div>
                    <div class="w-1/5 text-center">班長/組長或單位主管評核-去年 Rating by Team Leader/Immediate Manager-LastYear</div>
                    <div class="w-1/5 text-center">班長/組長或單位主管評核 Rating by Team Leader/Immediate Manager</div>
                  </div>
                  <div class="flex bg-blue-50">
                    <div class="w-1/5">品質 Quality Conscious<br>
                      持續投注於助益光益包裝工業(股)公司成功之正向、具體效度之工作面向。
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastSelfEvaluation_Quality"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Quality' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.SelfEvaluation_Quality"
                        class="border rounded"
                        clearable
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Quality' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastManagerEvaluation_Quality"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Quality' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.ManagerEvaluation_Quality"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Quality' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/5">誠實正直 Integrity<br>
                      行為操守符合高標準之企業守則及價值
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastSelfEvaluation_Interity"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Interity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.SelfEvaluation_Interity"
                        class="border rounded"
                        clearable
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Interity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastManagerEvaluation_Interity"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Interity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.ManagerEvaluation_Interity"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Interity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                  </div>
                  <div class="flex bg-blue-50">
                    <div class="w-1/5">專業 Professionalism<br>
                      對於負責職掌範圍職位內容，具備透徹之知識及作業技巧
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastSelfEvaluation_Professionalism"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Professionalism' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.SelfEvaluation_Professionalism"
                        class="border rounded"
                        clearable
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Professionalism' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastManagerEvaluation_Professionalism"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Professionalism' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.ManagerEvaluation_Professionalism"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Professionalism' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/5">持續進步 Contribution to Continuous Improvement<br>
                      工作成果奠基於不斷尋求之創新、挑戰熱枕
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastSelfEvaluation_Progress"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Progress' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.SelfEvaluation_Progress"
                        class="border rounded"
                        clearable
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Progress' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastManagerEvaluation_Progress"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Progress' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.ManagerEvaluation_Progress"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Progress' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                  </div>
                  <div class="flex bg-blue-50">
                    <div class="w-1/5">解決問題 Problem Solving<br>
                      在不確定或缺乏完整訊息的狀況下，能彈性、效率地達致工作成果
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastSelfEvaluation_Problem"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Problem' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.SelfEvaluation_Problem"
                        class="border rounded"
                        clearable
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Problem' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastManagerEvaluation_Problem"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Problem' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.ManagerEvaluation_Problem"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Problem' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/5">團隊精神 Teamwork<br>
                      在團隊組織中，熟諳如何與他人共同完成工作目標
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastSelfEvaluation_Teamwork"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Teamwork' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.SelfEvaluation_Teamwork"
                        class="border rounded"
                        clearable
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Teamwork' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastManagerEvaluation_Teamwork"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Teamwork' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.ManagerEvaluation_Teamwork"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Teamwork' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                  </div>
                  <div class="flex bg-blue-50">
                    <div class="w-1/5">自動自發 Spontaneity<br>
                      具活力，並自我驅動以尋求成就的工作動能
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastSelfEvaluation_Spontaneity"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Spontaneity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.SelfEvaluation_Spontaneity"
                        class="border rounded"
                        clearable
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Self_Spontaneity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.LastManagerEvaluation_Spontaneity"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Spontaneity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                    <div class="w-1/5 p-2">
                      <vxe-select
                        v-model="data.XmlData.ManagerEvaluation_Spontaneity"
                        class="border rounded"
                        clearable
                        disabled
                      >
                        <vxe-option
                          v-for=" option in standardOption "
                          :key="'Manager_Spontaneity' + option.value"
                          :value="option.value"
                          :label="`${option.label}`"
                        ></vxe-option>
                      </vxe-select>
                    </div>
                  </div>
                </div>
              </template>
              <template #partB>
                <div class="w-full ml-3">
                  <span class="font-black">PART B - Performance Evaluation 整體績效評核</span>
                </div>
              </template>
              <!-- <template #partATitle>
                <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                  <thead>
                    <tr>
                      <th
                        class="border border-gray-400 bg-gray-200 py-2 px-4 text-center font-black"
                        colspan="5"
                      >Objectives Achievement 工作目標達成</th>
                    </tr>
                  </thead>
                </table>
              </template> -->
              <!-- <template #partBTitle>
                <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                  <thead>
                    <tr>
                      <th
                        class="border border-gray-400 bg-gray-200 py-2 px-4 text-center font-black"
                        colspan="5"
                      >Core Competency Evaluation 核心職能評核</th>
                    </tr>
                  </thead>
                </table>
              </template> -->
              <!-- <template #directions02>
                <div class="w-full ml-3">
                  A.<span class="font-black">！</span>Beneath each performance objective summarize and results during the
                  review period.(Major 3-5 Items)
                  員工條列三至五項具體完成事項說明，並整理評估期間之達成目標
                </div>
              </template> -->
              <!-- <template #directions03>
                <div class="w-full ml-3">
                  B.<span class="font-black">！</span>Give constructive suggestive suggestions for how performance could be
                  improved
                  就未完善事項，提出具體、建設性自我改進建議
                </div>
              </template> -->
              <template #directions04>
                <div class="w-full ml-3">
                  品質 Quality Conscious<br>
                  持續投注於助益光益包裝工業(股)公司成功之正向、具體效度之工作面向。
                </div>
              </template>
              <template #fedirections05>
                <div class="font-black">
                  ！在工作中是否遭遇到特別的困難影響您的工作表現? 您的主管可提供那些必要的協助資源嗎?<br>
                  Is there any particular diffculty that has caused negative impact of your performance? What are the
                  resource that your manager could provide to you?
                </div>
              </template>
              <template #column-Quality>
                <div class="w-full ml-3">
                  品質 Quality Conscious<br>
                  持續投注於助益光益包裝工業(股)公司成功之正向、具體效度之工作面向。
                </div>
              </template>
              <template #column-Interity>
                <div class="w-full ml-3">
                  誠實正直 Integrity<br>
                  行為操守符合高標準之企業守則及價值
                </div>
              </template>
              <template #column-Professionalism>
                <div class="w-full ml-3">
                  專業 Professionalism<br>
                  對於負責職掌範圍職位內容，具備透徹之知識及作業技巧
                </div>
              </template>
              <template #column-Progress>
                <div class="w-full ml-3">
                  持續進步 Contribution to Continuous Improvement<br>
                  工作成果奠基於不斷尋求之創新、挑戰熱枕
                </div>
              </template>
              <template #column-Problem>
                <div class="w-full ml-3">
                  解決問題 Problem Solving<br>
                  在不確定或缺乏完整訊息的狀況下，能彈性、效率地達致工作成果
                </div>
              </template>
              <template #column-Teamwork>
                <div class="w-full ml-3">
                  團隊精神 Teamwork<br>
                  在團隊組織中，熟諳如何與他人共同完成工作目標
                </div>
              </template>
              <template #column-Spontaneity>
                <div class="w-full ml-3">
                  自動自發 Spontaneity<br>
                  具活力，並自我驅動以尋求成就的工作動能
                </div>
              </template>
              <template #column-Overall>
                <table class="w-full table-auto w-auto border-collapse border border-gray-400">
                  <thead>
                    <tr>
                      <th
                        class="border border-gray-400 bg-gray-200 py-2 px-4 text-center font-black"
                        colspan="5"
                      >Overall Rating 整體自評(請參考下列評分標準：E, D, G, I, C)</th>
                    </tr>
                  </thead>
                </table>
              </template>
              <template #column-Next>
                <div class="w-full ml-3">
                  <span class="font-black">PART C - OBJECTIVE PLAN FOR NEXT REVIEW PERIOD 下次評核之個人工作目標設定</span><br>
                  <span class="font-black">請同仁訂定下次績效評核前之工作目標/計畫，請以條列式陳述於下；並和直屬主管溝通討論</span><br>
                </div>
              </template>
              <template #column-Signature>
                <div class="w-full ml-3">
                  <span class="font-black">AGREEMENT & SIGNATURE 簽名</span>
                </div>
              </template>
              <!-- 年終績效評估 34 -->
            </vxe-form>
          </template>
          <template #modal-footer="{ row }">
            <div class="flex justify-between">
              <div>
                <vxe-button
                  v-if="row.Id && (row.Status == 0 || row.Status == 4)"
                  type="button"
                  status="primary"
                  content="送出文件"
                  @click="sendDocument(row)"
                ></vxe-button>
              </div>
              <div>
                <vxe-button
                  v-if="row.Status !== 0 && row.Status !== 4"
                  type="button"
                  status="primary"
                  content="關閉"
                  @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"
                ></vxe-button>
                <vxe-button
                  v-else
                  type="submit"
                  status="primary"
                  content="確認"
                  @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"
                ></vxe-button>
              </div>
            </div>
            <!-- <vxe-button v-if="row.Status !== 0" type="button" status="primary" content="關閉" @click="() => { grid.isModalPopup = false; grid.editingRow = null; }"></vxe-button>
            <vxe-button v-else type="submit" status="primary" content="確認" @click="$refs.form.validate(errMap => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button> -->
            <!-- <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button> -->
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent($event, column, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent($event, column, option, $panel)"
                />
              </div>
            </div>
          </template>
          <template #operate="{ row }">
            <button
              v-if="row.Status > 0 && row.Status < 4 || row.Status == 5"
              class="button rounded-full mr-1 bg-blue-600 text-white"
              title="查看"
              @click.stop="grid.edit(row)"
            >
              <FontAwesome
                icon="book-open"
                class="w-4 h-4"
              />
            </button>
            <div
              v-else
              class="flex justify-center"
            >
              <button
                class="button rounded-full mr-1 bg-blue-600 text-white"
                title="編輯"
                @click.stop="grid.edit(row)"
              >
                <FontAwesome
                  icon="highlighter"
                  class="w-4 h-4"
                />
              </button>
              <button
                type="button"
                class="button rounded-full bg-red-600 text-white"
                title="刪除"
                @click.stop="grid.remove(row)"
              >
                <FontAwesome
                  icon="trash"
                  class="w-4 h-4"
                />
              </button>
            </div>
            <button
                class="button rounded-full mr-1 bg-blue-600 text-white"
                title="預覽"
                @click.stop="exportReport(row)"
              >
                <FontAwesome
                  icon="download"
                  class="w-4 h-4"
                />
              </button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal
      resize
      v-model="flowModal.visible"
      width="70%"
      title="文件流程"
      height="600"
      show-zoom="true"
    >
      <InProgressFlowTable
        ref="flowGrid"
        v-bind="flowModal.gridOptions"
        :modelValue="flowModal.visible"
        :contentType="flowModal.selectedRow.ContentType"
        :read="flowModal.selectedRow.Status != 0"
        :sid="flowModal.selectedRow.Id"
      />
    </vxe-modal>
    <!-- <vxe-modal v-model="flowModal.visible" width="60%" height="500" title="新增年終績效評估" show-zoom="true">
      <FlowTable ref="flowGrid" :visible="flowModal.visible" @submit="flowModal.save" />
    </vxe-modal>
    <vxe-modal v-model="deleteModal.visible" width="30%" height="350" title="刪除年終績效評估" show-zoom="true">
      <DeleteTable ref="deleteGrid" :visible="deleteModal.visible" @save="deleteModal.save" />
    </vxe-modal> -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { computed, defineComponent, ref, Condition, Operator, reactive, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import { getformOption, getType } from '@/libs/formItems';
import InvoiceQuarterlyItemTable from "@/components/document/InvoiceQuarterlyItemTable.vue";
import BOMUsageQuantityModifyItemTable from "@/components/document/BOMUsageQuantityModifyItemTable.vue";
import MaterialSubstitutesItemsTable from "@/components/document/MaterialSubstitutesItemsTable.vue";
import AssessmentItemsTable from "@/components/document/AssessmentItemsTable.vue";
import ProductNumberModifuItemsTable from "@/components/document/ProductNumberModifuItemsTable.vue";
import PurchaseRequisitionItemsTable from "@/components/document/PurchaseRequisitionItemsTable.vue";
import OrderItemsTable from "@/components/document/OrderItemsTable.vue";
import CustomerCreditItemsTable from "@/components/document/CustomerCreditItemsTable.vue";
import CheckRecordItemsTable from "@/components/document/CheckRecordItemsTable.vue";
import IQCItemsTable from "@/components/document/IQCItemsTable.vue";
import TypeAProductionCheckItemsTable from "@/components/document/TypeAProductionCheckItemsTable.vue";
import WinderItemsTable from "@/components/document/WinderItemsTable.vue";
import RollItemsTable from "@/components/document/RollItemsTable.vue";
import ProductionPackageQCItemsTable from "@/components/document/ProductionPackageQCItemsTable.vue";
import SalesItemsTable from "@/components/document/SalesItemsTable.vue";
import StaffItemsTable from "@/components/document/StaffItemsTable.vue";
import TrackingItemsTable from "@/components/document/TrackingItemsTable.vue";
import FinalQualityInspectionReportItemsTable from "@/components/document/FinalQualityInspectionReportItemsTable.vue";
import HumidityCheckRecordItemsTable from "@/components/document/HumidityCheckRecordItemsTable.vue";
import JobTickeDocumentItemsTable from "@/components/document/JobTickeDocumentItemsTable.vue";
import RemittanceSlipItemsTable from "@/components/document/RemittanceSlipItemsTable.vue";
import PerformanceEvaluationItemsATable from "@/components/document/PerformanceEvaluationItemsATable.vue";
import PerformanceEvaluationItemsBTable from "@/components/document/PerformanceEvaluationItemsBTable.vue";
import PerformanceEvaluationLastYearItemsATable from "@/components/document/PerformanceEvaluationLastYearItemsATable.vue";
import PerformanceEvaluationLastYearItemsBTable from "@/components/document/PerformanceEvaluationLastYearItemsBTable.vue";
import InProgressFlowTable from "@/components/document-flow/InProgressFlowTable.vue";
import DocumentFileUpload from "@/components/file/DocumentFileUpload.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    InvoiceQuarterlyItemTable,
    BOMUsageQuantityModifyItemTable,
    MaterialSubstitutesItemsTable,
    AssessmentItemsTable,
    ProductNumberModifuItemsTable,
    PurchaseRequisitionItemsTable,
    OrderItemsTable,
    CustomerCreditItemsTable,
    CheckRecordItemsTable,
    IQCItemsTable,
    TypeAProductionCheckItemsTable,
    WinderItemsTable,
    RollItemsTable,
    ProductionPackageQCItemsTable,
    FinalQualityInspectionReportItemsTable,
    HumidityCheckRecordItemsTable,
    JobTickeDocumentItemsTable,
    RemittanceSlipItemsTable,
    PerformanceEvaluationItemsATable,
    PerformanceEvaluationItemsBTable,
    PerformanceEvaluationLastYearItemsATable,
    PerformanceEvaluationLastYearItemsBTable,
    InProgressFlowTable,
    DocumentFileUpload,
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]
    const selectedType = ref<any>('');

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };
    const canCreate = checkPermissions("DocumentFlowCreate");
    const canUpdate = checkPermissions("DocumentFlowUpdate");
    const canDelete = checkPermissions("DocumentFlowDelete");

    const gridOptions: GridOptions = {
      id: 'document',
      title: '年終績效評估',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'Title', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        // { field: "Type", title: "文件類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentType).find(e => e.Value === cellValue)?.Name : undefined },
        // { field: "ContentType", title: "表單類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentContentType).find(e => e.Value === cellValue)?.Name : undefined },
        { field: 'Submitter.Person.Name', title: '申請人', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CurrentSignatureLevel', title: '目前簽核層級', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Status', title: '簽核狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.DocumentStatus).find(e => e.Value === cellValue)?.Name : undefined },
        {
          title: '文件流程',
          width: '100',
          slots: { default: "flow" }, align: 'center'
        },
      ],
      promises: {
        query: model ? async (params) => {
          params.condition!.and("ContentType", Operator.Equal, 34);
          if (params?.sortings?.length === 0) {
            params.sortings = [{ column: 'CreatedTime', order: 1 }];
          }
          if (user && !user.IsAdmin && !user.isUnitManager && !user.isDepManager && !user.isHR) {
            params.condition = new Condition("SubmitterId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          params.condition!.and("Status", Operator.IsNotNull).and("Status.Value", Operator.NotEqual, 6); //送出狀態 .and("Status.Value", Operator.NotEqual, 0);
          if (user && user.userDepartmentId && user.userDepartmentId !== 3 && !user.isHR && (user.isUnitManager || user.isDepManager)) {
            if (user.isDepManager) {
              console.log('isDepManager', user.userManagerDep);
              console.log(user.userManagerDep);
              //params.condition = new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
              params.condition!.and(new Condition("IsDepManager", Operator.Equal, user.userManagerDep));
              //params.condition = new Condition("Submitter.DepartmentId", Operator.IsNotNull)new Condition("Submitter.DepartmentId", Operator.In, user.userManagerDep).and(params.condition!);
            }
            if (user.isUnitManager) {
              console.log('isUnitManager');
              params.condition!.and(new Condition("IsUnitManager", Operator.Equal, user.userManagerUnit));
            }
          }
          return await model.dispatch('document/query', params);
        } : undefined,
        queryAll: model ? () => model.dispatch('document/query') : undefined,
        save: model
          ? (params) => model.dispatch('document/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true, fullscreen: true }
    }

    const form = ref<any>();
    const readType = ref(false);
    const defaultFormOptions: VxeFormProps = {
      items: [
        // {
        //   field: 'Title',
        //   title: '名稱',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '請輸入文字' },
        //     attrs: { type: 'text' }
        //   }
        // },
        {
          field: 'SubmittingTime',
          title: '提交時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期' } }
        },
        // {
        //   field: 'Type',
        //   title: '文件類型',
        //   span: 12,
        //   itemRender: { name: "$select", props: {}, options: model ? Object.values(model.enums.DocumentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        // {
        //   field: 'ContentType',
        //   title: '表單類型',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentContentType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
        {
          field: 'SubmitterId',
          title: '申請人',
          span: 12,
          slots: { default: "column-submitter" }
        },
        // {
        //   field: 'CurrentSignatureLevel',
        //   title: '目前簽核層級',
        //   span: 12,
        //   itemRender: {
        //     name: '$input',
        //     props: { placeholder: '', type: 'number', disabled: true },
        //   }
        // },
        // {
        //   field: 'Status',
        //   title: '簽核狀態',
        //   span: 12,
        //   itemRender: { name: "$select", props: { disabled: true }, options: model ? Object.values(model.enums.DocumentStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        // },
      ],
      rules: {
        Title: [{ type: 'string', required: true }],
        SubmittingTime: [{ required: true }],
        Type: [{ required: true }],
        ContentType: [{ required: true }],
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("employee/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const departmentSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("department/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("department/query", params) // eslint-disable-line
        }
      }
    };

    const initAddData = async (row: any, callback: any) => {
      console.log('formance-user', user);
      console.log('row', row);
      row.SubmitterId = user?.Id;
      row.SubmittingTime = new Date();
      row.Type = getType(34);
      var queryTitle = user?.Name + (new Date().getFullYear() - 1).toString() + "年度年終績效評估";
      if(new Date()<new Date(2025, 1, 1, 0, 0 , 0)){
        row.Title = user?.Name + (new Date().getFullYear()).toString() + "年度年終績效評估";
      }else{
        row.Title = user?.Name + (new Date().getFullYear() - 1).toString() + "年度年終績效評估";
      }
      row.Status = 0;
      readType.value = false;
      row.ContentType = 34;
      row.XmlData = {} as any;
      row.XmlData.InvoiceQuarterlyItems = [] as any;
      row.XmlData.ApplicationDate = new Date();
      if(new Date()<new Date(2025, 1, 1, 0, 0 , 0)){
        row.XmlData.Annual = (new Date().getFullYear()).toString();
      }else{
        row.XmlData.Annual = (new Date().getFullYear() - 1).toString();
      }
      
      row.XmlData.DepartmentId = user?.userDepartmentId;
      row.XmlData.Shift = user?.shift;
      row.XmlData.Position = user?.positionTitle;
      row.XmlData.Seniority = user?.seniority;
      row.XmlData.Manager = user?.userManager;
      //取得去年資料
      row.XmlData.LastYearItemsA = [] as any;
      row.XmlData.LastYearItemsB = [] as any;
      console.log(' queryTitle',queryTitle);
      const lastyear = await model!.dispatch("document/findLast", queryTitle);
      console.log('lastyear', lastyear);
      if(lastyear){
        console.log('lastyear.XmlData.ItemsA', lastyear.XmlData.ItemsA);
        console.log('lastyear.XmlData.ItemsB', lastyear.XmlData.ItemsB);
        //PART A 個人績效評估
        if(lastyear.XmlData.ItemsA){
          row.XmlData.LastYearItemsA = lastyear.XmlData.ItemsA;
        }
        //PART B 我改進建議
        if(lastyear.XmlData.ItemsB){
          row.XmlData.LastYearItemsB = lastyear.XmlData.ItemsB;
        }
        if(lastyear.XmlData.SelfEvaluation_Quality){
          row.XmlData.LastSelfEvaluation_Quality = lastyear.XmlData.SelfEvaluation_Quality;
        }
        if(lastyear.XmlData.ManagerEvaluation_Quality){
          row.XmlData.LastManagerEvaluation_Quality = lastyear.XmlData.ManagerEvaluation_Quality;
        }
        if(lastyear.XmlData.SelfEvaluation_Interity){
          row.XmlData.LastSelfEvaluation_Interity = lastyear.XmlData.SelfEvaluation_Interity;
        }
        if(lastyear.XmlData.ManagerEvaluation_Interity){
          row.XmlData.LastManagerEvaluation_Interity = lastyear.XmlData.ManagerEvaluation_Interity;
        }
        if(lastyear.XmlData.SelfEvaluation_Professionalism){
          row.XmlData.LastSelfEvaluation_Professionalism = lastyear.XmlData.SelfEvaluation_Professionalism;
        }
        if(lastyear.XmlData.ManagerEvaluation_Professionalism){
          row.XmlData.LastManagerEvaluation_Professionalism = lastyear.XmlData.ManagerEvaluation_Professionalism;
        }
        if(lastyear.XmlData.SelfEvaluation_Progress){
          row.XmlData.LastSelfEvaluation_Progress = lastyear.XmlData.SelfEvaluation_Progress;
        }
        if(lastyear.XmlData.ManagerEvaluation_Progress){
          row.XmlData.LastManagerEvaluation_Progress = lastyear.XmlData.ManagerEvaluation_Progress;
        }
        if(lastyear.XmlData.SelfEvaluation_Problem){
          row.XmlData.LastSelfEvaluation_Problem = lastyear.XmlData.SelfEvaluation_Problem;
        }
        if(lastyear.XmlData.ManagerEvaluation_Problem){
          row.XmlData.LastManagerEvaluation_Problem = lastyear.XmlData.ManagerEvaluation_Problem;
        }
        if(lastyear.XmlData.SelfEvaluation_Teamwork){
          row.XmlData.LastSelfEvaluation_Teamwork = lastyear.XmlData.SelfEvaluation_Teamwork;
        }
        if(lastyear.XmlData.ManagerEvaluation_Teamwork){
          row.XmlData.LastManagerEvaluation_Teamwork = lastyear.XmlData.ManagerEvaluation_Teamwork;
        }
        if(lastyear.XmlData.SelfEvaluation_Spontaneity){
          row.XmlData.LastSelfEvaluation_Spontaneity = lastyear.XmlData.SelfEvaluation_Spontaneity;
        }
        if(lastyear.XmlData.ManagerEvaluation_Spontaneity){
          row.XmlData.LastManagerEvaluation_Spontaneity = lastyear.XmlData.ManagerEvaluation_Spontaneity;
        }
        if(lastyear.XmlData.Overall_Employee){
          row.XmlData.LastOverall_Employee = lastyear.XmlData.Overall_Employee;
        }
        if(lastyear.XmlData.Overall_UnitManager){
          row.XmlData.LastOverall_UnitManager = lastyear.XmlData.Overall_UnitManager;
        }
        //PART C 下次評核之個人工作目標設定
        row.XmlData.LastNext_Objectives = lastyear.XmlData.Next_Objectives;
        row.XmlData.LastNext_Measurement = lastyear.XmlData.Next_Measurement;
        row.XmlData.LastNext_UnitManager = lastyear.XmlData.Next_UnitManager;
        row.XmlData.LastNext_Manager = lastyear.XmlData.Next_Manager;
      }
      formOptions.items = defaultFormOptions.items;
      formOptions.rules = defaultFormOptions.rules;

      var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 ? false : true) as any;
      let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
      Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
      for (const key in contentTypeOption.rules) defaultCopy.rules[key] = contentTypeOption.rules[key];
      formOptions.items = defaultCopy.items;
      formOptions.rules = defaultCopy.rules;
      console.log('new row：',row);
      await changeUser(row);
      grid.value.isModalPopup = true;
      callback();
    }

    const setDefaultReadOnly = (data: any) => {
      // data.items[0].itemRender.props.readonly = true;
      // data.items[1].itemRender.props.readonly = true;
      // data.items[2].itemRender.props.disabled = true;
      // data.items[3].itemRender.props.disabled = true;
      return data;
    }

    const onGridEdit = async (row: any, callback: any) => {
      const entity = row.Id ? await model?.dispatch('document/find', row.Id) : undefined
      Object.assign(row, entity);
      if (row.Id) {
        readType.value = false;
        var contentTypeOption = getformOption(row.ContentType, model, row.Status == 0 || row.Status == 4 ? false : true) as any;
        let defaultCopy = JSON.parse(JSON.stringify(defaultFormOptions));
        if (row.Status != 0) { //唯讀
          console.log('row.Status != 0', row.Status)
          if (row.Status == 4) {
            readType.value = false;
          } else {
            readType.value = true;
          }
          defaultCopy = setDefaultReadOnly(defaultCopy);
        }
        Array.prototype.push.apply(defaultCopy.items, contentTypeOption.items);
        for (const key in contentTypeOption.rules) {
          defaultCopy.rules[key] = contentTypeOption.rules[key];
        }
        formOptions.items = defaultCopy.items;
        formOptions.rules = defaultCopy.rules;
        fileModal.show(row);
      }
      callback()
    }

    const itemsTable = ref<any>();
    const ProductModifyItemsTable = ref<any>();
    const FinishedProductModifyItemsTable = ref<any>();
    const ReRollModifyItemsTable = ref<any>();
    const MaterialSubstitutesItemsTable = ref<any>();
    const AssessmentItemsTable = ref<any>();
    const ProductNumberModifuItemsTable = ref<any>();
    const PurchaseRequisitionItemsTable = ref<any>();
    const OrderItemsTable = ref<any>();
    const CustomerCreditItemsTable = ref<any>();
    const CheckRecordItemsTable = ref<any>();
    const IQCItemsTable = ref<any>();
    const TypeAProductionCheckItemsTable = ref<any>();
    const WinderItemsTable = ref<any>();
    const RollItemsTable = ref<any>();
    const ProductionPackageQCItemsTable = ref<any>();
    const FinalQualityInspectionReportItemsTable = ref<any>();
    const SalesItemsTable = ref<any>();
    const StaffItemsTable = ref<any>();
    const TrackingItemsTable = ref<any>();
    const HumidityCheckRecordItemsTable = ref<any>();
    const JobTickeDocumentItemsTable = ref<any>();
    const RemittanceSlipItemsTable = ref<any>();
    const PerformanceEvaluationItemsATable = ref<any>();
    const PerformanceEvaluationItemsBTable = ref<any>();
    const PerformanceEvaluationLastYearItemsATable = ref<any>();
    const PerformanceEvaluationLastYearItemsBTable = ref<any>();
    const isTableErrorA = ref<any>(false);
    const isTableErrorB = ref<any>(false);
    const isCoreCompetencyError = ref<any>(false);
    const formSubmit = (row: any, submit: any) => {
      if (row.ContentType == 34) { //年終績效評估
        var performanceEvaluationItemsA = PerformanceEvaluationItemsATable?.value.getData();
        var performanceEvaluationItemsB = PerformanceEvaluationItemsBTable?.value.getData();
        var performanceEvaluationLastYearItemsA = PerformanceEvaluationLastYearItemsATable?.value.getData();
        var performanceEvaluationLastYearItemsB = PerformanceEvaluationLastYearItemsBTable?.value.getData();
        const { SelfEvaluation_Quality, SelfEvaluation_Interity, SelfEvaluation_Professionalism, SelfEvaluation_Progress, SelfEvaluation_Problem, SelfEvaluation_Teamwork, SelfEvaluation_Spontaneity } = row.XmlData
        if (performanceEvaluationItemsA) {
          row.XmlData.ItemsA = performanceEvaluationItemsA;
        }
        if (performanceEvaluationItemsB) {
          row.XmlData.ItemsB = performanceEvaluationItemsB;
        }
        if (performanceEvaluationLastYearItemsA) {
          row.XmlData.LastYearItemsA = performanceEvaluationLastYearItemsA;
        }
        if (performanceEvaluationLastYearItemsB) {
          row.XmlData.LastYearItemsB = performanceEvaluationLastYearItemsB;
        }
        if (row.XmlData.ItemsA.length === 0) {
          isTableErrorA.value = true;
        } else {
          isTableErrorA.value = false;
        }
        if (row.XmlData.ItemsB.length === 0) {
          isTableErrorB.value = true;
        } else {
          isTableErrorB.value = false;
        }
        if (!SelfEvaluation_Quality || !SelfEvaluation_Interity || !SelfEvaluation_Professionalism || !SelfEvaluation_Progress || !SelfEvaluation_Problem || !SelfEvaluation_Teamwork || !SelfEvaluation_Spontaneity) {
          isCoreCompetencyError.value = true
        } else {
          isCoreCompetencyError.value = false
        }
      }
      if (isTableErrorA.value) {
        alert('isTableErrorA');
      } else if (isTableErrorB.value) {
        alert('isTableErrorB');
      } else if (isCoreCompetencyError.value) {
        alert('please complete the Core Competency Evaluation')
      } else {
        submit();
      }
    }

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/DocumentReport?docId=${row.Id}`)
    }

    onMounted(() => {
      selectedType.value = 34;
    })

    /** 資訊需求申請使用 */
    const typesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementItem).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const reasonsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementICauseReason).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };
    const annexSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ITRequirementIAnnex).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const wrokOrderItemsSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.WorkOrderType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const applicationTypesSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PaymentApplicationType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const purchaseRequisitionTypeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.PurchaseRequisitionType).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const jobTickeSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "JobTickeNo",
      valueField: "Id",
      columns: [
        {
          field: "JobTickeNo",
          title: "工單號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("jobTicke/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("jobTicke/query", params) // eslint-disable-line
        }
      }
    };

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("customer/find", value), // eslint-disable-line
        query: params => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          return model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const sendDocument = async (row: any) => {
      if (row.Id && confirm("確定要送出文件嗎?")) {
        try {
          await model?.dispatch('document/send', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已送出文件`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("DocumentId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Document_' + fileModal.selectedRow.Id, fileType: 2 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const flowGrid = ref<any>()
    const flowModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition("DataMode", Operator.Equal, 0).and("DocumentId", Operator.Equal, flowModal.selectedRow.Id).and("NotifyOnly", Operator
              .Equal, false
            )
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0) {
              params.sortings = [{ column: "Level", order: 0 }, { column: "CreatedTime", order: 0 }];
            }
            // var selectedStatus = flowGrid?.value.getSelectedStatus();
            // if (selectedStatus != null) {
            //   params.condition.and("Status", Operator.Equal, selectedStatus)
            // }
            return model.dispatch("inProgressFlow/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('inProgressFlow/query') : undefined,
          save: model ? (params) => model.dispatch('inProgressFlow/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        flowModal.visible = true;
        flowModal.selectedRow = row;
        if (flowGrid.value) flowGrid.value.refresh();
      }
    })

    const changeUser = async (row: any) => {
      if (row && row.SubmitterId) {
        const result = await model!.dispatch("employee/find", row.SubmitterId);
        row.XmlData.EmployeeName = result.Person.Name;
        row.XmlData.EmployeeNo = result.Number;
        row.XmlData.Signature_Employee = result.Person.Name;
        //row.XmlData.DepartmentId = 
      }
    };

    const foldSetting = ref({
      "PartA": true,
      "PartB": true,
      "PartC": true,
      "ObjectivesAchievement": true,
      "CoreCompetencyEvaluation": true,
      "OverallRating": true,
      "Objectives_Achievement": true,
    })
    const fold = (target: string) => {
      (foldSetting.value as any)[target] = !(foldSetting.value as any)[target];
    };

    const standardOption = ref([
      { value: "E", label: "E" },
      { value: "D", label: "D" },
      { value: "G", label: "G" },
      { value: "I", label: "I" },
      { value: "C", label: "C" }
    ])

    return {
      canCreate,
      grid,
      form,
      gridOptions,
      formOptions,
      userSelectorOptions,
      departmentSelectorOptions,
      selectedType,
      model,
      initAddData,
      onGridEdit,
      itemsTable,
      formSubmit,
      readType,
      annexSelectorOptions,
      reasonsSelectorOptions,
      typesSelectorOptions,
      wrokOrderItemsSelectorOptions,
      ProductModifyItemsTable,
      ReRollModifyItemsTable,
      FinishedProductModifyItemsTable,
      MaterialSubstitutesItemsTable,
      applicationTypesSelectorOptions,
      AssessmentItemsTable,
      ProductNumberModifuItemsTable,
      PurchaseRequisitionItemsTable,
      purchaseRequisitionTypeSelectorOptions,
      OrderItemsTable,
      CustomerCreditItemsTable,
      CheckRecordItemsTable,
      IQCItemsTable,
      TypeAProductionCheckItemsTable,
      WinderItemsTable,
      RollItemsTable,
      ProductionPackageQCItemsTable,
      FinalQualityInspectionReportItemsTable,
      SalesItemsTable,
      TrackingItemsTable,
      StaffItemsTable,
      HumidityCheckRecordItemsTable,
      JobTickeDocumentItemsTable,
      RemittanceSlipItemsTable,
      jobTickeSelectorOptions,
      customerSelectorOptions,
      PerformanceEvaluationItemsATable,
      PerformanceEvaluationItemsBTable,
      PerformanceEvaluationLastYearItemsATable,
      PerformanceEvaluationLastYearItemsBTable,
      sendDocument,
      flowGrid,
      flowModal,
      fileGrid,
      fileModal,
      changeUser,
      exportReport,
      standardOption,
      foldSetting,
      fold
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>

<style>
.vxe-form--item-inner {
  display: block !important;
}

.vxe-form--item-title {
  width: 100% !important;
  text-align: left !important;
}

.vxe-form--item-content {
  width: 100% !important;
}
</style>
